import React from 'react';
import PropTypes from 'prop-types';
import { config } from 'BoomTown';
import { TextButton } from 'coreComponents';
import BallerBox from 'components/BallerBox/component';
import FAIcon from 'components/core/FAIcon';
import { HOMEPAGE_BALLERBOX } from 'cypress_constants';

const HomepageBallerBox = (props) => {
  const homepageProps = {
    renderSearchButton: true,
    inputGroupProps: { className: 'ballerbox-wrapper--large' },
    searchByProps: {
      contentProps: {
        className: 'mt-16',
      },
    },
  };

  /**
   * Render button to search nearby when on mobile. The button is disabled while fetching the user's
   * location. We render this UI only on the mobile homepage since we do not display the typical
   * "Search By" button alongside the Autocomplete input on mobile.
   *
   * @returns {ReactNode}
   */
  const renderNearbySearchButton = () => {
    let content = (
      <>
        <FAIcon icon="location-arrow" type="solid" /> Search Properties Near Me
      </>
    );

    if (props.isFetchingLocation) {
      content = <FAIcon className="fa-2x" icon="spinner" animation="spin" />;
    }

    return (
      <TextButton
        className="ballerbox__nearby-button--homepage"
        onClick={props.onSelectNearbyMe}
        disabled={props.isFetchingLocation}
      >
        {content}
      </TextButton>
    );
  };

  return (
    <BallerBox
      searchButtonProps={{ dataCY: HOMEPAGE_BALLERBOX.SEARCH_BUTTON }}
      {...props}
      {...homepageProps}
    >
      {!config.powerfulFeaturesDeprecated && renderNearbySearchButton()}
    </BallerBox>
  );
};

HomepageBallerBox.propTypes = {
  isFetchingLocation: PropTypes.bool,
  onSelectNearbyMe: PropTypes.func,
};

export default HomepageBallerBox;
