import connectWPCustomizer from 'hoc/connectWPCustomizer';

export default connectWPCustomizer(
  [
    {
      name: 'bt-hh-update-sell-button-text',
      replaceProps: (props, data) => ({
        ...props,
        sellButtonText: data,
      })
    },
    {
      name: 'bt-hh-update-buy-button-text',
      replaceProps: (props, data) => ({
        ...props,
        buyButtonText: data,
      })
    },
    {
      name: 'bt-hh-update-buy-tab-text',
      replaceProps: (props, data) => ({
        ...props,
        buyTabText: data,
      })
    },
    {
      name: 'bt-hh-update-sell-tab-text',
      replaceProps: (props, data) => ({
        ...props,
        sellTabText: data,
      })
    },
    {
      name: 'bt-hh-update-default-tab',
      replaceProps: (props, data) => ({
        ...props,
        defaultTab: data,
      })
    }
  ],
  (props) => ({
    sellButtonText: props.sellButtonText ? props.sellButtonText : '',
    buyButtonText: props.buyButtonText ? props.buyButtonText : '',
    sellTabText: props.sellTabText ? props.sellTabText : '',
    buyTabText: props.buyTabText ? props.buyTabText : '',
    defaultTab: props.defaultTab ? props.defaultTab : ''
  })
);
