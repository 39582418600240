/**
 * Are we in the WordPress Customizer or nah?
 * @return bool
 */
export default function isWPCustomizer() {
  try {
    return !!window.wp.customize;
  } catch (e) {
    return false;
  }
}
