
/* eslint-disable camelcase */
import { connect } from 'react-redux';
import { config } from 'BoomTown';
import { getFavoriteIDs } from 'models/visitor/reducers/selectors';
import { isMobile } from 'selectors/browser';
import { push } from 'redux-little-router';

import HomepageMap from './component';

export default connect(
  (state) => ({
    isMobile: isMobile(state),
    favoriteIDs: getFavoriteIDs(state),
    hpMap: config.hp_map,
  }),
  {
    handlePinClick: push,
  }
)(HomepageMap);
