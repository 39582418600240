import { connect } from 'react-redux';
import getAccount from 'models/account/reducers/selectors';
import { getSpecialRules } from 'models/specialRules/reducers/selectors';
import { getVisitorID } from 'models/visitor/reducers/selectors';
import { getIsFetchingLocation } from 'components/BallerBox/reducer';
import * as BallerBoxActions from 'components/BallerBox/actions';
import * as HomepageBallerBoxActions from './actions';
import HomepageBallerBox from './component';

export default connect(
  (state) => {
    // TODO: Refactor to only pass the values as props that are actually needed by the component.
    const account = getAccount(state);

    return {
      isFetchingLocation: getIsFetchingLocation(state),
      account,
      specialRules: getSpecialRules(state),

      // Only using visitorID for the suggestions JSONp request. Only using JSONp bc the SuggestAPI
      // apparently doesn't have CORS headers.
      // TODO: Check up on the SuggestAPI and using JSONp to see if there's a better alternative now
      visitorID: getVisitorID(state),
    };
  },
  {
    onEmptySubmit: HomepageBallerBoxActions.emptySubmitClick,
    onSelectSuggestion: HomepageBallerBoxActions.selectSuggestion,
    onSelectNearbyMe: BallerBoxActions.selectNearbySearch,
  }
)(HomepageBallerBox);
