/**
 * A set of event handlers that need to be bound and unbound, as a group, to a
 * @see google.maps.MVCObject.
 */
export default class MapListeners {
  /**
   * @param {MapEventHandler[]} handlers
   */
  constructor(handlers) {
    /** @type {MapEventHandler[]} */
    this.handlers = handlers;
  }

  /**
   * Bind the collection of listeners to an object
   *
   * @param {google.maps.MVCObject} obj
   */
  bindAllToMap(obj) {
    this.handlers = this.handlers.map(handler => ({
      ...handler,
      listener: obj.addListener(handler.key, handler.cb)
    }));
  }

  /**
   * Bind an individual event listener to an Object
   * @param {google.maps.MVCObject} obj
   * @param {string} key
   */
  bindListenerToMap(obj, key) {
    this.handlers = this.handlers.map(handler => (
      handler.key !== key ?
        handler : { ...handler, listener: obj.addListener(handler.key, handler.cb) }
    ));
  }

  /**
   * Unbind the collection of listeners.
   */
  unbindAllFromMap() {
    this.handlers.forEach(handler => handler.listener.remove());
  }

  /**
   * Unbind an individual event listener from an object
   * @param {string} key
   */
  unbindListenerFromMap(key) {
    this.handlers.forEach(handler => handler.key === key && handler.listener.remove());
  }
}
