/* eslint-disable no-confusing-arrow */
import { connect } from 'react-redux';
import { search } from 'BoomTown';
import { getGeolocation } from 'services/geolocation/reducer';
import { getPolygon } from 'selectors/router';
import { isMobile } from 'selectors/browser';
import { getMapBounds, getZoom } from 'models/search/reducers/selectors';
import * as a from './actions';
import * as selectors from './reducer';
import {
  getIsNearbyActive,
  getIsInDrawingMode,
  shouldPanToCurrentLocation,
  getMapType,
} from '../reducer';

export const getPinsFromState = (state, favoriteIDs) =>
  selectors.getPins(state, favoriteIDs);

export default connect(
  (state, ownProps) => ({
    // Values from search state
    bounds: getMapBounds(state),
    zoom: getZoom(state),

    pins: getPinsFromState(state, ownProps.favoriteIDs),
    locationSearch: selectors.getLocationSearch(state, search.locationParams),
    userMarker: getIsNearbyActive(state) ? getGeolocation(state) : null,
    polygon: getPolygon(state),
    isInDrawingMode: getIsInDrawingMode(state),
    shouldPanToCurrentLocation: shouldPanToCurrentLocation(state),
    mapTypeId: getMapType(state),
    isMobile: isMobile(state),
    shouldPanToMapBounds: selectors.getShouldPanToMapBounds(state),
  }),
  {
    onMapZoom: a.onMapZoom,
    onMapDragged: a.onMapDragged,
    onMapIdle: a.onMapIdle,
    onMapBoundsChanged: a.onMapBoundsChanged,
    onPinClicked: a.onPinClicked,
    onActivePinClick: a.onActivePinClick,
    dismissShelfOnTouch: a.dismissShelfOnTouch,
    dismissShelfOnDrag: a.dismissShelfOnDrag,
    hasPannedToCurrentLocation: a.hasPannedToCurrentLocation,
    receivePinsForStore: a.receivePinsForStore,
  }
);
