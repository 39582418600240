/* global google */
import { Component } from 'react';
import PropTypes from 'prop-types';
import simplify from 'simplify-geometry';
import withData from './withData';

// This is a limitation imposed by the API
const MAX_POINTS_ALLOWED = 15;
const polygonStyles = {
  geodesic: true,
  fillColor: '#4B86EB',
  fillOpacity: 0.2,
  strokeColor: '#4B86EB',
};

class PolygonOverlay extends Component {
  constructor(props) {
    super(props);

    this.overlay = new google.maps.OverlayView();
    this.overlay.draw = () => {};
    this.overlay.setMap(this.props.map);

    this.polygonStartListener = { remove() {} };
    this.polygonMoveListener = { remove() {} };
    this.placeholderPolygon = { setMap() {} };
  }

  componentDidMount() {
    const { map, onFailedSimplePath, onDrawEnd } = this.props;
    this.enableDrawingMode({ map, onFailedSimplePath, onDrawEnd });
  }

  componentWillUnmount() {
    this.cleanupListeners();
  }

  /**
   *
   * @param {Object} args
   * @param {google.maps.Map} args.map
   * @param {() => void} args.onFailedSimplePath
   * @param {({path:PolygonPath}) => void} args.onDrawEnd
   */
  enableDrawingMode({ map, onFailedSimplePath, onDrawEnd }) {
    const { addDomListener, addDomListenerOnce } = google.maps.event;
    const mapDiv = map.getDiv();
    let isTouchable = false;

    if (this.props.isMobile || 'ontouchstart' in document.documentElement === true) {
      isTouchable = true;
    }

    // We need to adjust the clients touch events to accomodate the top bar
    const correctionFromTop = mapDiv.getBoundingClientRect().top || 0;
    const correctionFromLeft = mapDiv.getBoundingClientRect().left || 0;

    // Wait for the user to start drawing
    this.polygonStartListener = addDomListenerOnce(
      mapDiv,
      isTouchable ? 'touchstart' : 'mousedown',
      (startEvent) => {
        startEvent.preventDefault();
        const drawing = new google.maps.Polyline({ map, clickable: false, strokeColor: '#4B86EB' });

        // Add points to the drawing on touchmove
        this.polygonMoveListener = addDomListener(mapDiv, isTouchable ? 'touchmove' : 'mousemove', (moveEvent) => {
          moveEvent.preventDefault();
          const latLng = this.coordsFromTouch(
            moveEvent,
            correctionFromLeft,
            correctionFromTop,
            this.overlay.getProjection(),
          );
          drawing.getPath().push(latLng);
        });

        // Binding the event on window in case you the cursor goes outside the map
        // or on the map buttons
        addDomListenerOnce(window, isTouchable ? 'touchend' : 'mouseup', () => {
        // Cleanup
          drawing.setMap(null);
          this.cleanupListeners();

          // Simplify the polygon to a limit our API can handle
          const simplePath = this.calcSimplePath(drawing);
          if (!simplePath) {
            onFailedSimplePath();
            this.enableDrawingMode({ map, onDrawEnd, onFailedSimplePath });
            return;
          }

          // Add a placeholder polygon while we wait for this to be committed
          this.placeholderPolygon = new google.maps.Polyline({
            map,
            polygonStyles,
            path: simplePath,
          });
          onDrawEnd({ path: simplePath });
        });
      },
    );
  }

  coordsFromTouch(moveEvent, xOffset, yOffset, projection) {
    let point;

    if (moveEvent.touches) {
      point = new google.maps.Point(
        parseInt(moveEvent.touches[0].clientX - xOffset, 10),
        parseInt(moveEvent.touches[0].clientY - yOffset, 10),
      );
    } else {
      point = new google.maps.Point(
        parseInt(moveEvent.clientX - xOffset, 10),
        parseInt(moveEvent.clientY - yOffset, 10),
      );
    }

    // We use the container as a frame of reference because that better aligns with the
    // coordinate system the events are coming from.
    return projection.fromContainerPixelToLatLng(point);
  }

  cleanupListeners() {
    this.polygonStartListener.remove();
    this.polygonMoveListener.remove();
    this.placeholderPolygon.setMap(null);
  }

  /**
   * Simplifies a complex path using the Ramer–Douglas–Peucker algorithm via the
   * `simplify-geometry` lib
   *
   * @param {google.maps.Polyline} polyline
   * @returns {false | {lat: number, lng: number}[]}
   */
  calcSimplePath(polygon) {
    const pointsIn = polygon
      .getPath()
      .getArray()
      .map((p) => { return [p.lat(), p.lng()]; });

    if (pointsIn.length < 3) {
      return false;
    }

    if (pointsIn.length <= MAX_POINTS_ALLOWED) {
      return pointsIn.map((p) => { return { lat: p[0], lng: p[1] }; });
    }

    // We want a higher tolerance when you are more zoomed in
    // but it is very performant so we'll just run it all the time
    const tolerance = [
      0.0000625,
      0.000125,
      0.00025,
      0.0005,
      0.001,
      0.002,
      0.003,
      0.005,
      0.01,
      0.015,
      0.02,
      0.03,
      0.05,
      0.08,
      0.1,
    ];
    for (let i = 0; i < tolerance.length; i++) {
      const pointsOut = simplify(pointsIn, tolerance[i]);
      if (pointsOut.length <= MAX_POINTS_ALLOWED) {
        return pointsOut.map((p) => { return { lat: p[0], lng: p[1] }; });
      }
    }
    return false;
  }

  render() {
    return null;
  }
}

PolygonOverlay.propTypes = {
  map: PropTypes.shape({}).isRequired,
  onDrawEnd: PropTypes.func.isRequired,
  onFailedSimplePath: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default withData(PolygonOverlay);
