/* eslint-disable global-require */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import loadable from 'react-loadable';
import _ from 'underscore';
import $ from 'jquery';
import { connect, Provider } from 'react-redux';
import Flickity from 'flickity-bg-lazyload';
import bt from 'BoomTown';
import { push } from 'redux-little-router';

import isWPCustomizer from 'utility/isWPCustomizer';
import Listing from 'legacy/Model/listing';
import HomepageHero from 'components/HomepageHero';
import ContactView from 'legacy/Views/Layouts/contact';
import FlickityView from 'legacy/Views/Listings/flickityView';
import ListingCardView from 'legacy/Views/Listings/card';
import AgentSearch from 'components/AgentSearch/Container';
import WidgetListings from 'components/WidgetListings/Container';

import EnterpriseBallerBox from 'components/EnterpriseBallerBox';
import HomepageBallerBox from 'components/HomepageSearch/Consumer';
import DualSearchBox from 'components/HomepageHero/DualSearch';

// Plugins
import { PluginFairHousingNotice } from 'coreComponents';

export class PureHome extends Component {
  static displayName = 'Home';

  static propTypes = {
    html: PropTypes.string,
    push: PropTypes.func
  };

  static contextTypes = {
    store: PropTypes.object
  };

  /**
   * An array of Backbone views that are managed within the DOM of this
   * component.
   * @type {Array<View>}
   */
  childViews = [];

  /**
   * @type {HTMLElement}
   */
  el = null;

  /**
   * Renders Home Search Bar Shortcode
   * @return {void}
   */
  homeSearchBarShortcode = () => {
    const elements = document.querySelectorAll('.js-homesearchbar-shortcode');
    if (!elements) {
      return;
    }

    for (const element of elements) {
      if (element.dataset.theme === 'search') {
        ReactDOM.render(
          (
            <Provider store={this.context.store}>
              <HomepageBallerBox />
            </Provider>
          ),
          element,
        );
      } else if (element.dataset.theme === 'enterprise') {
        ReactDOM.render(
          (
            <Provider store={this.context.store}>
              <EnterpriseBallerBox />
            </Provider>
          ),
          element,
        );
      } else if (element.dataset.theme === 'dual_search') {
        const props = JSON.parse(element.dataset.props);
        ReactDOM.render(
          (
            <Provider store={this.context.store}>
              <DualSearchBox {...props} />
            </Provider>
          ),
          element,
        );
      }
    }
  };

  /**
   * Instantiate views whose DOM lies inside this React component and push them
   * onto `this.childViews` for cleanup.
   */
  componentDidMount() {

    // Homesearch Bar Shortcode
    this.homeSearchBarShortcode();

    // Make it saner if this doesn't exist.
    if (typeof bt.config.homepageHeroPlugin === 'undefined') {
      bt.config.homepageHeroPlugin = '';
    }

    // Error Handling
    let homepageHeroEnabled;
    try {
      homepageHeroEnabled = !_.isEmpty(bt.config.homepageHeroPlugin) && bt.config.homepageHeroPlugin !== '';
    } catch (e) {
      homepageHeroEnabled = false;
    }

    if (bt.config.overrideSearchBox) {
      // We don't have to clean up after this because the link is going to point
      // to a new subdomain
      const bbEnterpriseNode = document.querySelector('.js-enterprise-search-template');
      if (bbEnterpriseNode) {
        this.renderChildView(bbEnterpriseNode, EnterpriseBallerBox);
      }
    } else if (homepageHeroEnabled || isWPCustomizer()) { // Homepage Hero plugin is enabled
      for (const [template, args] of Object.entries(bt.config.homepageHeroPlugin)) {
        const props = {
          ...args.props,
          isEnterprise: bt.config.overrideSearchBox
        };
        if (template) {
          const heroNode = document.querySelector(args.node);
          if (heroNode) {
            this.renderChildView(heroNode, HomepageHero[template], props);
          }
        }
      }

      // We also want to render the actual ballerbox if we're in the Customizer and the Homepage
      // Hero plugin is enabled
      if (isWPCustomizer()) {
        const bbNode = document.querySelector('.jsx-hero__search-bar');
        if (bbNode) {
          const SearchComponent = bt.config.overrideSearchBox ? EnterpriseBallerBox : HomepageBallerBox;
          this.renderChildView(bbNode, SearchComponent);
        }
      }
    } else {
      const bbNode = document.querySelector('.jsx-hero__search-bar');
      if (bbNode) {
        this.renderChildView(bbNode, HomepageBallerBox);
      }
    }

    // Fair Housing Plugin
    const pluginFairHousingHeaderNode = document.querySelector('#bt-plugin-fair-housing-notice-react-header');
    if (pluginFairHousingHeaderNode) {
      this.renderChildView(pluginFairHousingHeaderNode, PluginFairHousingNotice, { placement: 'header' });
    }

    const pluginFairHousingFooterNode = document.querySelector('#bt-plugin-fair-housing-notice-react-footer');
    if (pluginFairHousingFooterNode) {
      this.renderChildView(pluginFairHousingFooterNode, PluginFairHousingNotice, { placement: 'footer' });
    }
    // End Fair Housing Plugin

    this._themeInitialization();

    const contactViews = [...this.el.querySelectorAll('.js-contact-form')]
      .map(el => new ContactView({ el, model: window.bt.visitor }));

    this.childViews.push(...contactViews);

    const flickityViews = [...this.el.querySelectorAll('.js-card-slider')]
      .map(el => new FlickityView({ el }));
    this.childViews.push(...flickityViews);

    const widgetListingViews = [
      ...this.el.querySelectorAll('.js-widget-listings')
    ].map(el => {
      // Render the widget
      const props = JSON.parse(el.dataset.props);
      ReactDOM.render(
        <Provider store={this.context.store}>
          <WidgetListings {...props} />
        </Provider>,
        el
      );

      // Adhere to the child view api
      return {
        remove() {
          ReactDOM.unmountComponentAtNode(el);
        }
      };
    });
    this.childViews.push(...widgetListingViews);

    const cardViews = [...this.el.querySelectorAll('.js-card')].map(el => {
      const model = new Listing({ _ID: el.dataset.listingid });
      return new ListingCardView({
        model,
        inCollection: false,
        el,
        renderUI: false
      });
    });
    this.childViews.push(...cardViews);

    const agentSearchNode = document.querySelector('.js-agent-office-search');
    if (agentSearchNode) {
      ReactDOM.render(
        <Provider store={this.context.store}>
          <AgentSearch />
        </Provider>,
        agentSearchNode
      );

      const agentSearchView = {
        remove() {
          ReactDOM.unmountComponentAtNode(agentSearchNode);
        }
      };

      this.childViews.push(agentSearchView);
    }

    bt.lazyload.refresh();
  }

  componentWillUnmount() {
    this.childViews.forEach(v => { v.remove(); });
  }

  /**
   * Render a React component at a given node and push them onto `this.childViews` for cleanup.
   *
   * @param {string} node The node to mount the Component to
   * @param {Component} ChildComponent The Component to be mounted
   */
  renderChildView(node, ChildComponent, props) {
    ReactDOM.render(
      <Provider store={this.context.store}>
        <ChildComponent {...props} />
      </Provider>,
      node
    );

    const childView = {
      remove() {
        ReactDOM.unmountComponentAtNode(node);
      }
    };

    this.childViews.push(childView);
  }

  /**
   * The primary JS difference amongst themes occurs on the homepage. Depending
   * on the theme, bind a number of views.
   */
  _themeInitialization() {
    const theme = window.bt.utility.currentTheme();

    // Scout Specific
    if (theme === 'wp-scout-theme') {
      const HomepageMap = loadable({
        loader: () => bt.deps.loadGoogleMaps().then(() => require('components/HomepageMap').default),
        loading: () => null,
      });

      const mapViewNode = document.getElementById('js-homepage-map');
      if (mapViewNode) {
        ReactDOM.render(
          <Provider store={this.context.store}>
            <HomepageMap />
          </Provider>,
          mapViewNode
        );

        const homepageMapView = {
          remove() {
            ReactDOM.unmountComponentAtNode(mapViewNode);
          }
        };

        this.childViews.push(homepageMapView);
      }

      $('#tagline').fitText(1.2, {
        minFontSize: '18px',
        maxFontSize: '40px'
      });
    }

    // Willow Specific
    if (theme === 'wp-willow-theme') {
      $('#tagline').fitText(1.2, {
        minFontSize: '18px',
        maxFontSize: '60px'
      });

      // Top Locations Homepage Slider
      const locationSelector = '.js-location-slider';
      if (document.querySelector(locationSelector)) {
        new Flickity(locationSelector, { // eslint-disable-line no-new
          cellSelector: '.bt-location-slider__cell',
          cellAlign: 'left',
          contain: true,
          freeScroll: true,
          pageDots: false
        });
      }
    }

    /*
    * CNS-4660
    * This code relies on edits made to the Testimonials Widget plugin code.
    * Should that update, this may not work. Check the initialization of this slider in class-testimonials-widget.
    */
    if (window.testimonialsWidget) {
      $(window.testimonialsWidget.sliderID).bxSlider(window.testimonialsWidget.options);
    }

    // CNS 4676 Initialize RealSatisfied Widget on Homepage
    if (window.rsw) {
      const realSatisfiedSettings = window.rsw.settings;
      if ($('.rsw-flexslider').length) {
        $('.rsw-flexslider').flexslider({
          namespace: 'rsw-',
          smoothHeight: false,
          touch: true,
          selector: '.rs-slides > li',
          slideshowSpeed: realSatisfiedSettings.speed * 1000,
          animation: realSatisfiedSettings.animationType,
          slideshow: realSatisfiedSettings.autoAnimate,
          animationLoop: true,
          pauseOnHover: true,
          directionNav: realSatisfiedSettings.displayArrows,
          controlNav: false
        });
      }
    }
  }

  render() {
    return (<div
      ref={c => (this.el = c)}
      dangerouslySetInnerHTML={{ __html: this.props.html }}
    />);
  }
}

export default connect(
  state => ({ html: state.cachedHtml.home }),
  { push }
)(PureHome);
