/* eslint-disable react/require-default-props */
/* global google */
import React from 'react';
import PropTypes from 'prop-types';
import { isEqual, isEmpty } from 'underscore';

import { mapstyle, config } from 'BoomTown';
import * as MapPool from 'utility/MapPool/mapPool';
import parsePolygonFromURL from 'utility/parsePolygonFromURL';
import SagaContext from 'hoc/SagaContext';
import { MAP } from 'cypress_constants';

import MarkerOverlay from './MarkerOverlay';
import Polygon from './Polygon';
import PolygonOverlay from './PolygonOverlay';
import MapListeners from './MapListeners';
import withData from './withData';
import MapPin from './MapPin';
import UserMarker from './UserMarker';
import mapSaga from './mapSaga';

export class Map extends React.PureComponent {
  /** @type google.maps.MapOptions */
  static defaultOptions = {
    minZoom: 5,
    maxZoom: null,
    disableDefaultUI: true,
    styles: mapstyle.default,
    clickableIcons: false, // Points of interest should not be clickable
    gestureHandling: 'greedy', // Gets rid of the "use two fingers to pan" message
  };

  constructor(props) {
    super(props);
    this.state = {
      /** @type {google.maps.Map} */
      map: undefined,
    };

    /**
     * A set of callbacks that will be bound to events dispatched on the map.
     * The advantage of this over directly binding props as callbacks is that
     * we don't have to keep track of when props change.
     */
    this.mapListeners = new MapListeners([
      {
        key: 'zoom_changed',
        cb: () => {
          this.props.onMapZoom(this.state.map.getZoom());
        },
      },
      {
        key: 'dragend',
        cb: () => {
          this.props.onMapDragged();
        },
      },
      {
        key: 'idle',
        cb: () => {
          this.props.onMapIdle();
        },
      },
      {
        key: 'bounds_changed',
        cb: () => {
          const bounds = this.state.map.getBounds();
          if (!bounds) {
            this.props.onMapBoundsChanged({});
            return;
          }

          const { north, south, east, west } = bounds.toJSON();
          this.props.onMapBoundsChanged({
            nelat: north,
            swlat: south,
            nelng: east,
            swlng: west,
          });
        },
      },
      {
        key: 'click',
        cb: () => {
          if (this.props.activeListingID) {
            this.props.dismissShelfOnTouch();
          }
        },
      },
      {
        key: 'dragstart',
        cb: () => {
          if (this.props.activeListingID) {
            this.props.dismissShelfOnDrag();
          }
        },
      },
    ]);
  }

  /**
   * Ask the map pool for a Google map instance and div, with the desired
   * config, and then append the div to one rendered by this component.
   */
  componentDidMount() {
    const homepageMapOptions = {
      gestureHandling: 'none',
      // Min and max zoom are set to the same value to ensure that the map does not
      // automatically reset its zoom level when navigating back to the homepage from
      // a property details page
      minZoom: this.props.zoom,
      maxZoom: this.props.zoom,
    };

    /**
     * We can't check for the `disableMapGestures` prop in the static method, so we can
     * instead spread the static object and re-assign the gestureHandling property before the
     * map is created.
     *
     * @type google.maps.MapOptions
    */
    const defaultOptions = {
      ...Map.defaultOptions,
      ...(this.props.disableMapGestures ? homepageMapOptions : {}),
    };

    const map = MapPool.create(
      {
        ...defaultOptions,
        mapTypeId: this.props.mapTypeId,
        ...this.getInitialCenterAndZoom(),
      },
      'results-map-xp__map',
      this.el,
    );

    const specifiedInitialZoom = Boolean((this.props.bounds || this.props.hpBounds)
    && this.props.zoom);

    /** @type {Task} */
    if (!this.props.disableMapTask) {
      this.mapTask = this.props.runSaga(mapSaga, map);
    }

    // eslint-disable-next-line
    this.setState(
      {
        map,
      },
      () => {
        // Any listeners that are called will need to access the map via `state`.
        this.mapListeners.bindAllToMap(map);

        // We only want to pan the map one way
        if (this.props.userMarker !== null && this.props.shouldPanToCurrentLocation) {
          this.handlePanToUsersLocation();
        } else if (this.props.polygon) {
          this.fitBoundsToPolygon();
        } else if (!specifiedInitialZoom) {
          // Only fit bound to pins when there is no opinion on how the map should
          // initially be positioned.
          this.fitBoundsForLocationSearches();
        }
      },
    );
  }

  componentDidUpdate(prevProps) {
    const { locationSearch, shouldPanToCurrentLocation, shouldPanToMapBounds } = this.props;
    if (!isEmpty(locationSearch) && !isEqual(prevProps.locationSearch, locationSearch)) {
      this.fitBoundsForLocationSearches();
    }

    // Normally, calling a method from componentDidMount without an equality check against prevProps
    // is bad practice, but we're forced to here due to a timing issue with when we set the
    // `shouldPanToCurrentLocation` state. I tried doing an equality check between the current
    // and previous `userMarker` prop vals but this doesn't work if the user hasn't moved, but has
    // clicked the "Nearby Me" option in the ballerbox while `isNearbyActive` is already true.
    if (shouldPanToCurrentLocation) {
      this.handlePanToUsersLocation();
    }

    if (shouldPanToMapBounds !== prevProps.shouldPanToMapBounds) {
      // object that comes back contains a center and zoom property
      const initCoords = this.getInitialCenterAndZoom();
      this.state.map.panTo(initCoords.center);
      this.state.map.setZoom(initCoords.zoom);
    }
  }

  componentWillUnmount() {
    if (this.mapTask) {
      this.mapTask.cancel();
    }
    this.mapListeners.unbindAllFromMap();
    MapPool.destroy(this.state.map);
  }

  /**
   * Convert `this.props.bounds` to the center.
   *
   * @return {google.Maps.LatLng}
   */
  getInitialCenterAndZoom() {
    // Use Scout config values to set homepage map bounds and zoom
    if (this.props.hpBounds && this.props.zoom) {
      const { hplat, hplng } = this.props.hpBounds;

      return {
        center: new google.maps.LatLng(hplat, hplng),
        zoom: this.props.zoom,
      };
    }

    if (this.props.bounds && this.props.zoom) {
      const { nelat, nelng, swlat, swlng } = this.props.bounds;
      const bounds = new google.maps.LatLngBounds(
        { lat: swlat, lng: swlng },
        { lat: nelat, lng: nelng },
      );
      return {
        center: bounds.getCenter(),
        zoom: this.props.zoom,
      };
    }

    return {
      center: new google.maps.LatLng(config.defaultLat, config.defaultLng),
      zoom: this.props.zoom || 10,
    };
  }

  fitBoundsToPolygon() {
    const { polygon } = this.props;
    const { map } = this.state;

    const [points, err] = parsePolygonFromURL(polygon);
    if (err) {
      return;
    }
    const latLngBounds = points.reduce(
      (bounds, { lat, lng }) => { return bounds.extend({ lat, lng }); },
      new google.maps.LatLngBounds(),
    );
    map.fitBounds(latLngBounds);
  }

  /**
   * Determine if the y coord of the pin clicked is below a certain threshold. If so, pan the map
   * to the map pins coords with a 50px buffer on the y axis to prevent pin from being cut off
   * by the listing card.
   *
   * - Our OverlayView layer on the map exists in the middle of our map, so any y value above 0
   *   means the pin is below the y axis since it essentially equates to `top: ypx == below middle`
   *   && `top: -ypx == above middle`
   *
   * @param {Object} pin
   * @param {{ x: Number, y: Number }} point
   */
  handlePinClick = (pin, { y }) => {
    // Dispatch
    this.props.onPinClicked(pin);

    // Ensure the pin is visible
    if (this.props.isMobile && y > 0) {
      this.state.map.panTo({ lat: pin.lat, lng: pin.lng });
      this.state.map.panBy(0, 150);
    }

    if (!this.props.isMobile && this.props.activeListingID === pin.id) {
      this.props.onActivePinClick(pin.urlPath);
    }
  };

  handlePanToUsersLocation = () => {
    const { userMarker } = this.props;

    if (userMarker) {
      this.state.map.panTo({ lat: userMarker.lat, lng: userMarker.lng });
      this.state.map.setZoom(16);
      this.props.hasPannedToCurrentLocation();
    }
  };

  /**
   * Whenever the component is mounted or has been updated, fit the map's
   * bounds to the results if needed.
   *
   * @param {Object?} prevLocationSearch
   */
  fitBoundsForLocationSearches() {
    const { pins } = this.props;
    const { map } = this.state;

    if (pins.length) {
      this.mapListeners.unbindListenerFromMap('idle');
      // Temporarily set a limit on zoom and must reset it on the idle event.
      map.setOptions({ maxZoom: 18 });

      const latLngBounds = pins.reduce(
        (bounds, { lat, lng }) => { return bounds.extend({ lat, lng }); },
        new google.maps.LatLngBounds(),
      );
      map.fitBounds(latLngBounds);

      // Removing already unbound listeners is a no-op by the lib
      google.maps.event.addListenerOnce(map, 'idle', () => {
        map.setOptions({ maxZoom: null });
        this.mapListeners.bindListenerToMap(map, 'idle');
      });
    }
  }

  render() {
    // shouldDisplayAsMinimal defaults to true unless we explicitly pass the corresponding prop
    // to be false (such as for the HomePageMap) or if the map is zoomed < 11
    const shouldDisplayAsMinimal = this.props.shouldDisplayAsMinimal && this.props.zoom < 11;

    const items = this.props.pins.map((p) => {
      return {
        coords: { lat: p.lat, lng: p.lng },
        listingID: p.id,
        render: ({ x, y }) => {
          return (
            <MapPin
              key={p.id}
              text={p.text}
              sashType={p.sashType}
              isFavorite={p.isFavorite}
              isMinimal={shouldDisplayAsMinimal}
              style={{ left: `${x}px`, top: `${y}px` }}
              onClick={() => { return this.handlePinClick(p, { x, y }); }}
              isActive={p.id === this.props.activeListingID}
              isHovered={p.id === this.props.hoverListingID}
              isMuted={this.props.isMuted}
            />
          );
        },
      };
    });

    if (this.props.userMarker) {
      items.push({
        coords: this.props.userMarker,
        render: ({ x, y }) => {
          return (
            <UserMarker
              key="user-marker"
              style={{ left: `${x}px`, top: `${y}px` }}
              data-cy={MAP.USER_MARKER}
            />
          );
        },
      });
    }

    return (
      <div className={this.props.className} data-cy={MAP.CONTAINER} style={{ height: `${this.props.height}px` }} ref={(x) => { return (this.el = x); }}>
        {this.state.map && (
          <>
            {this.props.isInDrawingMode && <PolygonOverlay map={this.state.map} />}
            <Polygon map={this.state.map} polygon={this.props.polygon} />
            <MarkerOverlay
              map={this.state.map}
              items={items}
              receivePinsForStore={this.props.receivePinsForStore}
            />
          </>
        )}
      </div>
    );
  }
}

export const WithSagaContext = (props) => {
  return (
    <SagaContext.Consumer>
      {(runSaga) => { return <Map {...props} runSaga={runSaga} />; }}
    </SagaContext.Consumer>
  );
};

export default withData(WithSagaContext);

Map.propTypes = {
  className: PropTypes.string,

  /**
   * These are used for initial state only, IOW, the map is an _uncontrolled_ component when it
   * comes to it's bounds and zoom level. That state's source of truth is maintained by the map
   * instance. It is reported out via props, however, so that it can be stored in Redux, for
   * fetching results, etc.
   */
  bounds: PropTypes.shape({
    nelat: PropTypes.number.isRequired,
    nelng: PropTypes.number.isRequired,
    swlat: PropTypes.number.isRequired,
    swlng: PropTypes.number.isRequired,
  }),
  hpBounds: PropTypes.shape({
    hplat: PropTypes.number.isRequired,
    hplng: PropTypes.number.isRequired,
  }),
  zoom: PropTypes.number,
  onMapZoom: PropTypes.func,
  onMapDragged: PropTypes.func,
  onMapIdle: PropTypes.func,
  onMapBoundsChanged: PropTypes.func,

  // The search used to fetch `pins`, but only when a location param is present.
  locationSearch: PropTypes.shape({}),

  // Pins
  pins: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    sashType: PropTypes.string,
    isFavorite: PropTypes.bool.isRequired,
    urlPath: PropTypes.string,
  })).isRequired,
  receivePinsForStore: PropTypes.func,

  // Geolocation / Nearby
  userMarker: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  hasPannedToCurrentLocation: PropTypes.func,
  shouldPanToCurrentLocation: PropTypes.bool,
  shouldPanToMapBounds: PropTypes.bool,

  // Drawing / Polygon
  polygon: PropTypes.string,
  isInDrawingMode: PropTypes.bool.isRequired,

  // Map type; sat, roadmap
  mapTypeId: PropTypes.string.isRequired,

  // Shelf related
  activeListingID: PropTypes.number,
  onPinClicked: PropTypes.func.isRequired,
  onActivePinClick: PropTypes.func,
  dismissShelfOnTouch: PropTypes.func,
  dismissShelfOnDrag: PropTypes.func,

  // Side effects that need to be performed on the map
  // this is un/mounted along with the component
  runSaga: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  height: PropTypes.number,
  disableMapTask: PropTypes.bool,
  disableMapGestures: PropTypes.bool,

  // Desktop
  hoverListingID: PropTypes.number,

  // MapPin props
  isMuted: PropTypes.bool,
  shouldDisplayAsMinimal: PropTypes.bool,
};

Map.defaultProps = {
  locationSearch: {},
  shouldPanToMapBounds: false,
  isMuted: false,
  shouldDisplayAsMinimal: true,
};
