/* eslint jsx-a11y/label-has-associated-control: 0 */
/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { tenant } from 'BoomTown';
import { Cell, Grid } from 'components/core/Grid';
import { Input, PrimaryButton } from 'coreComponents';
import FAIcon from 'components/core/FAIcon';
import withData from './withData';

// Just putting all of this logic in this component for the moment though it may not be the best
class HomeValFinder extends Component {
  static propTypes = {
    buttonText: PropTypes.string,
    working: PropTypes.bool,
    onSubmit: PropTypes.func,
    handleRequest: PropTypes.func,
    onGeocodeError: PropTypes.func,
  };

  static defaultProps = {
    buttonText: 'Get Property Report',
  };

  constructor(props) {
    super(props);

    this.autocomplete = null;
    this.finderField = React.createRef();
    this.country = tenant.isCanadian ? 'ca' : 'us';
    this.autocompleteOptions = {
      types: ['geocode'],
      componentRestrictions: { country: this.country }
    };
    this.geocache = [];
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.finderField.current,
      this.autocompleteOptions
    );
  }

  /**
   * Give me a components obj from google, I'll give you a hash that makes sense.
   *
   * @param {Array} components
   * @returns
   */
  parseComponents = (components) => {
    const addr = {};

    for (const item of components) {
      switch (item.types[0]) {
        case 'street_number':
          addr.streetNumber = item.long_name;
          break;
        case 'route':
          addr.streetName = item.long_name;
          break;
        case 'locality':
        case 'sublocality':
          addr.city = item.long_name;
          break;
        case 'administrative_area_level_1':
          addr.state = item.short_name;
          break;
        case 'postal_code':
          addr.zip = item.long_name;
          break;
        case 'subpremise':
          addr.unit = item.long_name;
          break;
        default:
          break;
      }
    }

    addr.streetAddress = `${addr.streetNumber} ${addr.streetName}`;
    return addr;
  };

  /**
   * Parse the returned geocode result so we can make a valuation api request
   *
   * @param {Object} place
   */
  setPlace = (place) => {
    const addr = this.parseComponents(place.address_components);

    if (!addr.city) {
      this.props.onGeocodeError();
      return;
    } else if (!addr.streetNumber) {
      this.props.onGeocodeError();
      return;
    }

    this.props.handleRequest(addr);
  };

  /**
   * Use Google's Geocoder API to obtain a usable address object
   */
  geocode = (address) => {
    if (this.geocache[address]) {
      this.setPlace(this.geocache[address]);
      return;
    }

    const geocoder = new google.maps.Geocoder();
    const options = {
      address,
      region: this.country,
    };

    geocoder.geocode(
      options,
      (results, status) => {
        if (status !== 'OK') {
          this.props.onGeocodeError();
          return;
        } else if (results.length > 1) {
          this.props.onGeocodeError();
          return;
        } else if (results[0] && results[0].geometry.location_type.match(/(GEOMETRIC_CENTER)|(APPROXIMATE)/g)) {
          // Invalid location_type
          this.props.onGeocodeError();
          return;
        }

        const place = results[0];
        this.geocache[address] = place;
        this.setPlace(place);

        return;
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const address = this.finderField.current.value;

    if (address.trim() === '') {
      return;
    }
    this.props.onSubmit();
    this.geocode(address);
  };

  render() {
    const { buttonText, working } = this.props;
    const buttonIcon = working
      ? <FAIcon icon="spinner" type="solid" animation="spin" />
      : <span className="uk-visible-small"><FAIcon icon="search" type="solid" /></span>;

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <Grid alignItems="center">
          <Cell xs={10} md="fit" className="pr-12">
            <label htmlFor="bt-home-val__search-bar" style={{ display: 'none' }}>Address, #Unit, City, State, Zip</label>
            <Input
              id="bt-home-val__search-bar"
              placeholder="Address, #Unit, City, State, Zip"
              ref={this.finderField}
              formGroupProps={{ className: 'mb-0' }}
            />
          </Cell>
          <Cell md="none">
            <PrimaryButton type="submit" width="full">
              {buttonIcon}
              <span className="uk-hidden-small"> {working ? 'Fetching...' : buttonText}</span>
            </PrimaryButton>
          </Cell>
        </Grid>
      </form>
    );
  }
}

export default withData(HomeValFinder);
