import { connect } from 'react-redux';
import * as homeValActions from './actions';
import { getWorkingStatus } from './reducer';

export default connect(
  (state) => ({
    working: getWorkingStatus(state),
  }),
  {
    onSubmit: homeValActions.onHomeValSearchSubmit,
    handleRequest: homeValActions.onHomeValRequest,
    onGeocodeError: homeValActions.onGeocodeError,
  }
);
