import { connect } from 'react-redux';
import { isMobile } from 'selectors/browser';
import { onDrawEnd, onFailedSimplePath } from './actions';


export default connect(
  state => ({
    isMobile: isMobile(state),
  }),
  {
    onDrawEnd,
    onFailedSimplePath,
  }
);
