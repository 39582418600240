/* global google */
import { Component } from 'react';
import T from 'prop-types';
import parsePolygonFromURL from 'utility/parsePolygonFromURL';

/**
 * @see https://developers.google.com/maps/documentation/javascript/reference/polygon#PolygonOptions
 */
export default class Polygon extends Component {
  static propTypes = {
    /** @type {string} The base64 encoded representation of the string */
    polygon: T.string,
    map: T.any.isRequired,
  };

  componentDidMount() {
    if (this.props.polygon) {
      this.updatePolygon(this.props.polygon);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.polygon !== nextProps.polygon) {
      this.addedPolygon.setMap(null);

      if (nextProps.polygon !== '') {
        this.updatePolygon(nextProps.polygon);
      }
    }
  }

  componentWillUnmount() {
    this.addedPolygon.setMap(null);
  }

  addedPolygon = { setMap: () => {} };
  updatePolygon(polygon) {
    const [path, err] = parsePolygonFromURL(polygon);
    if (err) {
      return;
    }

    this.addedPolygon = new google.maps.Polygon({
      map: this.props.map,
      path,
      clickable: false,
      geodesic: true,
      fillColor: '#4B86EB',
      fillOpacity: 0.2,
      strokeColor: '#4B86EB',
    });
  }

  render() {
    return null;
  }
}
