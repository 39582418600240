/* eslint-disable camelcase,global-require */
import React, { useState } from 'react';
import T from 'prop-types';
import { api, utility } from 'BoomTown';
import { Map } from 'screens/ResultsMap/Map/Map';
import { mapListingToPin } from 'screens/ResultsMap/Map/reducer';
import { MOBILE_MAP_PAGE_COUNT, DESKTOP_MAP_PAGE_COUNT } from 'screens/ResultsMap/constants';
import { filterMapListings } from 'screens/ResultsMap/Map/effects/syncMapResults';
import Listing from 'legacy/Model/listing';

/**
 * @param {Object} props
 * @param {boolean} props.isMobile
 * @param {number[]} props.favoriteIDs
 * @param {homepageMapDefaults} props.hpMap
 * @param {function} props.handlePinClick
 * @returns {JSX.Element}
 */
export default function HomepageMap(props) {
  const {
    isMobile,
    favoriteIDs,
    hpMap: {
      hp_map_qs,
      hp_map_lat,
      hp_map_lon,
      hp_map_zoom,
    },
    handlePinClick,
    ...rest
  } = props;

  /** @type {[MapBounds, function]} */
  const [mapBounds, setMapBounds] = useState({});
  /** @type {[MapPin[], function]} */
  const [pins, setPins] = useState([]);

  /**
   * Callback bound to idle event dispatched from map component used to trigger setState hooks.
   * Here we build our query, make our API call and beefup our filtered listings and create our
   * pins array using the properties returned from beefed up listings.
   */
  const onMapIdle = async () => {
    let nextResultsQuery = JSON.parse(`{ "${hp_map_qs.replace(/&/g, '","').replace(/=/g, '":"')}" }`);

    nextResultsQuery = {
      ...nextResultsQuery,
      ...mapBounds
    };

    try {
      const searchResponse = await api.ajaxSearchPromise({
        sort: 'popularity',
        pageindex: 0,
        pagecount: isMobile ? MOBILE_MAP_PAGE_COUNT : DESKTOP_MAP_PAGE_COUNT,
        ...nextResultsQuery,
      });

      const { Items } = searchResponse.Result;

      /** @type {MapPin} */
      const newPins = Items?.reduce((filteredListings, listing) => {
        // Only add our listings that have coordinates and HideAddress !== true
        if (filterMapListings(listing)) {
          // We only need to call beefUp to build and retreive urlPath from listing object.
          // Once we start to breakup our listing model, we can replace this with a less costly func
          const beefedListing = new Listing(listing).beefUp();
          filteredListings.push(mapListingToPin(beefedListing, favoriteIDs));

          return filteredListings;
        }

        return filteredListings;
      }, []);

      setPins(newPins);
    } catch (e) {
      // eslint-disable-next-line
      console.error('ERROR fetching Homepage map results');
    }
  };

  /**
   * Set lastSearchRoute cookie and dispatch little Router's push action to route to
   * details page set by urlPath param
   * @param {MapPin} MapPin.urlPath - path to route to
   */
  const onPinClicked = ({ urlPath }) => {
    // When we click a pin, we need to set the `lastSearchRoute` cookie to the results map
    // route. There is a possiblity that a user may be squeezed when clicking through to a
    // property, and if they register and click the back button, we want to ensure that they
    // are sent to the results map.
    utility.cookie('lastSearchRoute', 'results-map');

    if (urlPath) {
      handlePinClick(urlPath);
    }
  };

  return (
    <Map
      {...rest}
      className="scout-homepage-map__map-container"
      hpBounds={{
        hplat: parseFloat(hp_map_lat, 10),
        hplng: parseFloat(hp_map_lon, 10),
      }}
      zoom={parseInt(hp_map_zoom, 10)}
      mapTypeId="roadmap"
      onMapBoundsChanged={setMapBounds}
      onMapIdle={onMapIdle}
      onPinClicked={onPinClicked}
      pins={pins}
      receivePinsForStore={() => pins}
      disableMapTask
      disableMapGestures
      isMuted
      shouldDisplayAsMinimal={false}
    />
  );
}

HomepageMap.propTypes = {
  favoriteIDs: T.arrayOf(T.number),
  zoom: T.number,
  handlePinClick: T.func,
  hpMap: T.object,
  isMobile: T.bool,
};
