/**
 *
 * @param {string} b64Polygon
 * @returns {[ {lat: number, lng: number}[], string ]}
 */
export default function parsePolygonFromURL(b64Polygon) {
  const polygonStr = window.atob(b64Polygon);
  if (polygonStr === '') {
    return [[], "Couldn't decode"];
  }

  /** @type {Array} */
  const polygonPoints = JSON.parse(polygonStr);
  if (!polygonPoints.length) {
    return [[], "Couldn't parse"];
  }

  // Use the first point to determine which keys correspond to lat/lng
  const { latKey, lngKey } = getLatLngKeys(polygonPoints[0]);
  if (!latKey || !lngKey) {
    return [[], 'Had trouble decoding valid keys'];
  }

  // CNS-3817: only push point if we were able to decode the key
  const polygonPath = polygonPoints.map(point => ({ lat: point[latKey], lng: point[lngKey] }));

  // 3+ points make a polygon
  if (polygonPath.length < 3) {
    return [[], 'Not enough points'];
  }

  return [polygonPath, null];
}

/**
 * We currently use lat/lng for all key names, but we had a bug where these various
 * key combinations were stored in the DB, so now we support them too
 */
export function getLatLngKeys(point) {
  const supportedPairs = [
    { lat: 'lat', lng: 'lng' },
    { lat: 'k', lng: 'B' },
    { lat: 'nb', lng: 'ob' },
    { lat: 'hb', lng: 'ib' },
    { lat: 'lb', lng: 'mb' },
    { lat: 'jb', lng: 'kb' },
    { lat: 'd', lng: 'e' },
    { lat: 'A', lng: 'F' },
    { lat: 'mb', lng: 'nb' },
    { lat: 'ob', lng: 'pb' },
    { lat: 'H', lng: 'L' },
    { lat: 'b', lng: 'd' },
    { lat: 'kb', lng: 'lb' },
    { lat: 'pb', lng: 'qb' },
    { lat: 'G', lng: 'K' },
    { lat: 'Ya', lng: 'Za' },
    { lat: 'J', lng: 'M' },
  ];
  let latKey = false;
  let lngKey = false;
  // TODO: error  iterators/generators require regenerator-runtime, which is too heavyweight for
  // this guide to allow them. Separately, loops should be avoided in favor of array iterations
  // no-restricted-syntax
  for (const pair of Array.from(supportedPairs)) {
    if (point[pair.lat] && point[pair.lng]) {
      latKey = pair.lat;
      lngKey = pair.lng;
      break;
    }
  }

  return { latKey, lngKey };
}
