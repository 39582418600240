/* global google */
import { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { isEqual, omit } from 'underscore';
import PinOverlay from './PinOverlay';

export default class MarkerOverlay extends Component {
  static propTypes = {
    map: PropTypes.object.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        coords: PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired,
        }).isRequired,
        render: PropTypes.func.isRequired,
      })
    ),
    receivePinsForStore: PropTypes.func,
  };

  static defaultProps = {
    items: [],
  };

  constructor(...args) {
    super(...args);
    this.state = {
      projection: null,
      overlayPane: null,
    };
    this.overlay = new PinOverlay({
      onAdd: this.updateProjection,
      onRemove: () => {},
      draw: this.updateProjection,
    });
    this.overlay.setMap(this.props.map);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.map !== this.props.map) {
      this.overlay.setMap(nextProps.map);
    }

    const filteredItems = this.props.items.map(i => omit(i, 'render'));
    const filteredNextItems = nextProps.items.map(i => omit(i, 'render'));

    if (!isEqual(filteredItems, filteredNextItems) && this.state.projection) {
      nextProps.receivePinsForStore(
        nextProps.items.map(({ coords, listingID }) => ({
          coords: this.mapFromLatLngToPoint(coords),
          listingID,
        })).filter(item => item.listingID)
      );
    }
  }

  componentWillUnmount() {
    this.overlay.setMap(null);
  }

  updateProjection = () => {
    this.setState({
      projection: this.overlay.getProjection(),
      overlayPane: this.overlay.getPanes().overlayMouseTarget,
    });
  };

  /**
   * Use the OverlayView (with knowledge of our map) to convert from
   * coordinates to a style attribute.
   *
   * @param {{ lat: number, lng: number }} obj
   * @returns {string}
   */
  mapFromLatLngToPoint({ lat, lng }) {
    const latLng = new google.maps.LatLng({ lat, lng });
    const { x, y } = this.state.projection.fromLatLngToDivPixel(latLng);
    return { x, y };
  }

  render() {
    if (!this.state.overlayPane || !this.state.projection) {
      return null;
    }

    return createPortal(
      this.props.items.map(({ coords, render }) => render(this.mapFromLatLngToPoint(coords))),
      this.state.overlayPane
    );
  }
}
