/* eslint-disable global-require */
import React from 'react';
import loadable from 'react-loadable';
import bt from 'BoomTown';

const LazyHomeValFinder = loadable({
  loader: () => bt.deps.loadGoogleMaps().then(() => require('components/HomepageHero/HomeValFinder/HomeValFinder').default),
  loading: () => <form><input type="text" className="uk-invisible" /></form>
});

export default LazyHomeValFinder;
