import React from 'react';
import { isEqual } from 'lodash';
import isWPCustomizer from 'utility/isWPCustomizer';

function defaultWPInitialState() {
  return {};
}

/**
 * Get the display name of a component so that we can augment it for the
 * displayName of the wrapping component.
 * @param {React.Component|function} c
 */
function getDisplayName(c) {
  return c.displayName || c.name || 'Component';
}

/**
 * Just like react-redux's `connect()`, but for listening to messages from WP's Customizer Control
 * Context to the Customizer's Previewer.
 *
 * @example
 * export default connectWPCustomizer(
 *   [{
 *     // An event message sent from the WP Customizer Control context to the Previewer
 *     // Ex.) `wp.customize.previewer.send('custom-message-from-customizer', myValue);`
 *     name: 'custom-message-from-customizer',
 *
 *     // A function that will be called whenever the event is triggered, and is
 *     // expected to return props that the component will be re-rendered with.
 *     // Passed the current props as the first arg., and the remaining arguments
 *     // will be those that are passed to handler of the specific event.
 *     replaceProps: (props, ...eventArgs) => {
 *     }
 *   }],
 *   function () {
 *     // You can reach out to models and build the initial props you'd like
 *     // passed in here, or alternatively pass them in from outside as props.
 *     return { myInitial: 'state' };
 *   }
 * )(MyComponent);
 *
 * @param {ReactClass} WrappedComponent
 * @param {Array<Object>} subscriptions
 * @param {Function} getInitialState
 * @return {Component}
 */
const connectWPCustomizer = (
  subscriptions,
  getInitialState = defaultWPInitialState
) => WrappedComponent => {
  // Only wrap the component if we're in the WP Customizer, otherwise just pass the unaltered
  // Component right along.
  if (!isWPCustomizer()) {
    return WrappedComponent;
  }

  class ConnectedWPComponent extends React.Component {
    constructor(props) {
      super(props);

      /**
       * The state of the component tree from the perspective of the event
       * handlers. This allows us to pass the most up-to-date state to the
       * `replaceState()` callbacks, irrespective of React's batching
       * strategy.
       * @type {Object}
       */
      this._state = {
        ...this.props,
        ...getInitialState(this.props)
      };
      this.state = {
        ...this.props,
        ...getInitialState(this.props)
      };

      /**
       * A cache of the attached event handlers. Used to detach handlers on `componentWillUnmount()`
       *
       * @type {Array<Object>}
       * @property {String} name
       * @property {Function} handler
       */
      this._handlers = [];

      // If we're not in the Customizer, then we shouldn't be here anyway, but just in case
      try {
        if (window.wp.customize.preview) {
          this._emitter = window.wp.customize.preview;
        }
      } catch (e) {
        this._emitter = null;
      }
    }

    /**
     * Attach event handlers according to the provided `subscriptions`
     */
    componentDidMount() {
      if (this._emitter) {
        subscriptions.forEach(e => {
          const handler = this._getHandleEvent(e);
          this._handlers.push({ name: e.name, handler });
          this._emitter.bind(e.name, handler);
        });
      }
    }

    // Ensure that both our atomic `_state` and `this.state` are updating in
    // response to incoming prop changes.
    componentDidUpdate(prevProps) {
      if (!isEqual(prevProps, this.props)) {
        this._state = {
          ...this._state,
          ...this.props,
        };
        // eslint-disable-next-line
        this.setState({
          ...this.props
        });
      }
    }

    /**
     * Detach event handlers using cached references in `this._handlers`
     */
    componentWillUnmount() {
      if (this._handlers) {
        this._handlers.forEach(h => {
          const { name, handler } = h;
          this._emitter.unbind(name, handler);
        });
      }

      this._handlers = [];
    }

    /**
     * Higher-order function that returns the handler for an event subscription
     * @param  {Object} eventObj
     * @return {Function} The event handler that will be passed whatever args. are passed by the
     * specific Customizer message event.
     */
    _getHandleEvent = eventObj => (...args) => {
      if (eventObj.replaceProps) {
        const newProps = eventObj.replaceProps(this._state, ...args);
        if (newProps) {
          this._state = {
            ...this._state,
            ...newProps,
          };
          this.setState(this._state);
        }
      }
    };

    render = () => <WrappedComponent {...this.state} />;
  }

  ConnectedWPComponent.displayName = `ConnectWPCustomizer(${getDisplayName(WrappedComponent)})`;
  return ConnectedWPComponent;
};

export default connectWPCustomizer;
