import React from 'react';
import FAIcon from 'components/core/FAIcon';

export default function UserMarker(props) {
  return (
    <div {...props} className="map-pin-usermarker">
      <div className="map-pin-usermarker__icon"><FAIcon icon="location" type="regular" /></div>
      <div className="map-pin-usermarker__perim" />
    </div>
  );
}
