/* eslint-disable no-confusing-arrow */
/* global google */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Cell } from 'components/core/Grid';
import { memoize } from 'underscore';
import { pure } from 'recompose';
import { MAP } from 'cypress_constants';

const getPinClasses = memoize(
  ({ isActive, isFavorite, shouldDisplayAsMinimal, isHovered, isMuted }) =>
    cx('map-pin', {
      'map-pin--active': isActive,
      'map-pin--favorite': isFavorite,
      'map-pin--minimal': shouldDisplayAsMinimal,
      'map-pin--hover': isHovered,
      'map-pin--muted': isMuted,
    }),
  ({ isActive, isFavorite, shouldDisplayAsMinimal, isHovered, isMuted }) =>
    [isActive, isFavorite, shouldDisplayAsMinimal, isHovered, isMuted].join('')
);

/**
 * Main Map Pin component
 * - Sets main `.map-pin` CSS class and various modifier classes
 * - Determines when to render the full map pin (when the user is zoomed) or the minimal map pin
 *   (when the user is zoomed out at a certain distance)
 */
const MapPin = ({ text, sashType, isActive, isHovered, isFavorite, isMinimal, style, onClick, isMuted }) => {
  const shouldDisplayAsMinimal = isHovered ? false : isMinimal;
  return (
    <div
      ref={ref => ref && google.maps.OverlayView.preventMapHitsFrom(ref)}
      className={getPinClasses({ isActive, isFavorite, shouldDisplayAsMinimal, isHovered, isMuted })}
      style={style}
      onClick={onClick}
      data-cy={MAP.PIN_MINIMAL}
    >
      <PinContents
        isMinimal={shouldDisplayAsMinimal}
        sashType={sashType}
        isFavorite={isFavorite}
        text={text}
      />
    </div>
  );
};
MapPin.defaultProps = {
  isFavorite: false,
  isMinimal: false,
  isActive: false,
  isHovered: false,
};
MapPin.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  isFavorite: PropTypes.bool,
  isHovered: PropTypes.bool,
  isMinimal: PropTypes.bool,
  isMuted: PropTypes.bool,
  sashType: PropTypes.string,
  onClick: PropTypes.func,
};
export default MapPin;

/**
 * An optimized, pure component specifying the contents of the MapPin div, so
 * that only the `style` on the div need to be reconciled.
 */
const PinContents = pure(({ isMinimal, ...rest }) =>
  isMinimal ? <MapPinMinimal {...rest} /> : <MapPinFull {...rest} />
);

/**
 * Full Map Pin
 *
 * - Always includes a text value
 * - Conditionally include a sashType dot
 * - Conditionally include a "favorite" icon (overrides rendering the sashType dot)
 */
const MapPinFull = ({ text, sashType, isFavorite }) => (
  <React.Fragment>
    <Grid alignItems="center" className="height-1-1">
      <Cell>{text}</Cell>
      {(sashType || isFavorite) && (
        <Cell xs={3}>
          <MapPinStatus sashType={sashType} isFavorite={isFavorite} />
        </Cell>
      )}
    </Grid>
    <div className="map-pin__arrow" />
  </React.Fragment>
);
MapPinFull.propTypes = {
  text: PropTypes.string.isRequired,
  isFavorite: PropTypes.bool,
  sashType: PropTypes.string,
};

/**
 * Minimal Map Pin
 *
 * - Only display a sashType dot or a "favorite" icon
 * - For pins that don't have a sashType, default to our primary color
 */
const MapPinMinimal = ({ sashType = 'default', isFavorite }) => (
  <Grid alignItems="center" className="height-1-1">
    <Cell>
      <MapPinStatus sashType={sashType} isFavorite={isFavorite} />
    </Cell>
  </Grid>
);
MapPinMinimal.propTypes = {
  isFavorite: PropTypes.bool,
  sashType: PropTypes.string
};

/**
 * sashType Dot for Map Pin
 *
 * - Renders a dot element or a "favorite" icon as a CSS background-image
 */
const MapPinStatus = ({ sashType, isFavorite }) => {
  const className = {
    [`map-pin__status--${sashType}`]: sashType && !isFavorite,
    'map-pin__status--favorited': isFavorite,
  };

  const dataCy = {
    [MAP.PIN_STATUS]: sashType && !isFavorite,
    [MAP.PIN_FAVORITE]: isFavorite,
  };

  return <div className={cx('map-pin__status', className)} data-cy={cx(dataCy)} />;
};
MapPinStatus.propTypes = {
  isFavorite: PropTypes.bool,
  sashType: PropTypes.oneOf([
    'default',
    'foreclosure',
    'new',
    'offmarket',
    'reduced',
    'shortsale',
    'undercontract',
    'openhouse',
    'virtualopenhouse',
    'rental',
    'sold',
    'pending',
    'comingsoon',
  ]),
};
