import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import HomepageBallerBox from 'components/HomepageSearch/Consumer';
import EnterpriseBallerBox from 'components/EnterpriseBallerBox';
import HomeValFinder from './HomeValFinder';
import withData from './withData';

class DualSearch extends React.Component {
  static propTypes = {
    sellButtonText: PropTypes.string,
    buyButtonText: PropTypes.string,
    defaultTab: PropTypes.string,
    buyTabText: PropTypes.string,
    sellTabText: PropTypes.string,
    isEnterprise: PropTypes.bool,
  };
  static defaultProps = {
    isEnterprise: false,
    defaultTab: 'sell',
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.defaultTab,
    };
  }

  setActiveTab = tab => {
    this.setState({ activeTab: tab });
  };

  componentDidMount() {
    HomeValFinder.preload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultTab !== this.props.defaultTab) {
      this.setActiveTab(this.props.defaultTab);
    }
  }

  renderActiveTab = () => {
    const { activeTab } = this.state;
    const {
      isEnterprise,
      sellButtonText,
      buyButtonText
    } = this.props;

    switch (activeTab) {
      case 'buy':
        if (isEnterprise) {
          return <EnterpriseBallerBox buttonText={buyButtonText} />;
        }

        return <HomepageBallerBox buttonText={buyButtonText || undefined} />;

      case 'sell':
      default:
        return <HomeValFinder buttonText={sellButtonText || undefined} />;
    }
  };

  render() {
    const { activeTab } = this.state;
    const { defaultTab, buyTabText, sellTabText } = this.props;

    return (
      <React.Fragment>
        <div className="bt-dual-search__tabs">
          <div
            className={cx(
              'bt-dual-search__tabs__tab',
              {
                'bt-dual-search__tabs__tab--active': activeTab === 'sell',
                'bt-dual-search__tabs__tab--first': defaultTab === 'sell',
                'bt-dual-search__tabs__tab--second': defaultTab !== 'sell',
              }
            )}
            onClick={() => this.setActiveTab('sell')}
            aria-label="Sell Tab"
          >
            <span>{sellTabText || 'Sell Your Home'}</span>
          </div>

          <div
            className={cx(
              'bt-dual-search__tabs__tab',
              {
                'bt-dual-search__tabs__tab--active': activeTab === 'buy',
                'bt-dual-search__tabs__tab--first': defaultTab === 'buy',
                'bt-dual-search__tabs__tab--second': defaultTab !== 'buy',
              }
            )}
            onClick={() => this.setActiveTab('buy')}
            aria-label="Buy Tab"
          >
            <span>{buyTabText || 'Buy a Home'}</span>
          </div>
        </div>

        <div className="bt-dual-search__wrapper">
          {this.renderActiveTab()}
        </div>
      </React.Fragment>
    );
  }
}

export default withData(DualSearch);
